.video-carousel-img:hover {
  transform: scale(1.1);
}

.auth-input:focus, .auth-input:active, .auth-input:focus-visible {
  border: 0 !important;
  border-color: transparent !important;
}

.alamiretv-button:hover, .alamiretv-button:focus {
  background-color: #DB2600 !important;
}